import React, { useEffect, useRef, useState, } from 'react';
import { useNavigate } from "react-router-dom";
import styles from '../../styles/savedVideos/SavedVideos.module.css';
import { watchSavedVideos } from "../../ApiCalls/SavedVideos/api_v1_saved_videos";
// import SidebarBottom from "../home/SidebarBottom";
import LogoAnimation from "../Animations/LogoAnimation";
import Share from "../home/Share";
import LinkCopied from "../home/LinkCopied";
import play from "../../images/v1/home/play_filled.png";
import SidebarBottomSavedVideos from './SidebarBottomSavedVideos';
import BufferingAnimation from '../Animations/BufferingAnimation';
function WatchSaved() {
    let encodedVideoId = (new URLSearchParams(window.location.search)).get("v");
    let decodedVideoId = atob((new URLSearchParams(window.location.search)).get("v"));
    let navigate = useNavigate();
    const [copied, setCopied] = useState(0);
    const [showShare, setShare] = useState(false);
    const [isBuffering, setIsBuffering] = useState(false);
    const [videoInfo, setVideoInfo] = useState({
        isloading: true,
        src: "",
        isPlaying: false,
        vid: decodedVideoId,
        like: 1
    });

    const videoRef = useRef(null);
    useEffect(() => {
        document.title = "Watch Saved Video | Ding";
    }, [])
    useEffect(() => {
        if (videoInfo.isloading === true) {

            let videoData = {
                videoId: encodedVideoId
            }
            watchSavedVideos(videoData).then(response => {
                let vSrc = response.data.data.video;
                setTimeout(() => {
                    setVideoInfo({ ...videoInfo, isloading: false, src: vSrc, vid: decodedVideoId })
                }, 1500);

            }).catch(error => {
                // console.log(error);
            });
        }
        return;
    }, [videoInfo.isloading === true])

    const playPause = (e) => {
        if (showShare) {
            setShare(false);
        }
        if (videoInfo.isPlaying === false) {
            // e.target.play();
            if (videoRef.current) {
                videoRef.current.play(); // Play the video
            }
            setVideoInfo({ ...videoInfo, isPlaying: true })
        }
        else {
            // e.target.pause();
            if (videoRef.current) {
                videoRef.current.pause(); // Play the video
            }
            setVideoInfo({ ...videoInfo, isPlaying: false })
        }

    }
    function likeDislikeHandler(like) {
        // console.log(like);
        setVideoInfo({ ...videoInfo, like: like })
    }
    function hideLogoAnimation(hide) {
        setVideoInfo({ ...videoInfo, isloading: false })
    }
    function copyToClipboardHandler() {
        setCopied(1);
        setTimeout(
            () => setCopied(0),
            600
        );
    }
    function showShareSection() {
        setShare(true)

    }
    const myCallback = () => {
        let path = "/trivia";
        navigate(path + "?id=" + videoInfo.vid);

    };
    function handleBuffering() {
        setIsBuffering(true);
    }
    function handleBufferingComplete() {
        setIsBuffering(false);
    }
    return (
        <>
            <div className={styles.watchVideoContainer}>
                {videoInfo.isloading ? <LogoAnimation hideLogoAnimation={hideLogoAnimation} /> :
                    <div className={styles.videoCard_player}>
                        {!videoInfo.isPlaying ?
                            <div className={styles.playBtnDiv}>
                                <img src={play} id={styles.playBtnSvg} onClick={playPause} alt="" />
                                <p className={styles.playText}>Play</p>
                            </div>
                            :
                            videoInfo.isPlaying && isBuffering ? <BufferingAnimation />
                                :
                                null
                        }
                        <video src={videoInfo.src} type="video/mp4; codecs=avc1.640028, mp4a.40.2" onWaiting={handleBuffering} onPlaying={handleBufferingComplete} className={styles.watchSavedVideoContent} onClick={playPause} preload="metadata" controlsList='nodownload' onEnded={() => myCallback()} ref={videoRef} playsInline={true} webkit-playsInline={true}>
                            {/* <source /> */}
                        </video>
                        <SidebarBottomSavedVideos vid={videoInfo.vid} like={videoInfo.like} likeDislikeHandler={likeDislikeHandler} showShareSection={showShareSection} />
                        {/* <SidebarBottom vid={videoInfo.vid} like={videoInfo.like} likeDislikeHandler={likeDislikeHandler} showShareSection={showShareSection} /> */}
                    </div>
                }
                {copied ? <LinkCopied /> : null}
                <div className={(showShare) ? `${styles.slideInUp}` : `${styles.slideOutDown}`}>
                    <Share vid={videoInfo.vid} copyToClipboardHandler={copyToClipboardHandler} />
                </div>
            </div>
        </>
    )
}

export default React.memo(WatchSaved)