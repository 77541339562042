import axiosClient from "../axiosClient";

export function getAuth(data) {
    let bearerTokenHeader = localStorage.getItem('token');
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
    return axiosClient.post('/authenticate', JSON.stringify(data));
}

export function getDecryptMsisdn(data) {
    let bearerTokenHeader = localStorage.getItem('token');
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
    return axiosClient.post('/dt/decrypt/msisdn', JSON.stringify(data))

}
export function getGuuid(data) {
    return axiosClient.post('/guuid', JSON.stringify(data));
}

export function getGuuidToken(data) {
    return axiosClient.post('/guuid/token', JSON.stringify(data));
}


export function guestExpiry(data) {
    let bearerTokenHeader = localStorage.getItem('token');
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
    return axiosClient.post('/guuid/expiry', JSON.stringify(data));
}