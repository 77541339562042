import React, { useLayoutEffect, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import SfxMainAudio from "../AutoPlayAudio/SfxMainAudio";
import styles from '../../styles/savedVideos/SavedVideos.module.css';
import { getSavedVideos, getSavedVideosByCategory } from "../../ApiCalls/SavedVideos/api_v1_saved_videos";
import Categories from "./Categories";
import BufferingAnimation from '../Animations/BufferingAnimation';

function SavedVideos() {
    let navigate = useNavigate();
    const [videoList, setVideoLists] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [showCategories, setShowCategories] = useState(false);
    const [showCategoriesVideoSelected, setShowCategoriesVideoSelected] = useState(false);
    const [showCategoriesVideoLoaded, setShowCategoriesVideoLoaded] = useState(false);

    useLayoutEffect(() => {
        document.body.style.backgroundColor = "#f8b600";
        document.querySelector("meta[name='theme-color']").setAttribute("content", "#f8b600");
        document.title = "Saved | Ding";
    }, []);

    useEffect(() => {
        if (isLoaded === false) {
            getSavedVideos().then(response => {
                if (response.status === 200 && response.data.message === "Ko") {
                    return;
                }
                if (response.status === 200 && response.data.message === "ok") {
                    setVideoLists([]);
                    response.data.data.forEach(element => {
                        setVideoLists(videoList => [...videoList, { 
                            "vid": btoa(element.vid), 
                            "src": element.poster, 
                            "type": "video/mp4",
                            "like": element.like || 0,
                            "description": element.description || ""
                        }]);
                    })
                    setIsLoaded(true);
                    return;
                }
            }).catch(error => {
                // Error handling
            });
        }
    }, [isLoaded === false]);

    function getCategory(data) {
        setVideoLists([]);
        handleToggle(3);
        getSavedVideosByCategory(data).then(response => {
            if (response.status === 200 && response.data.message === "Ko") {
                return;
            }
            if (response.status === 200 && response.data.message === "ok") {
                response.data.data.forEach(element => {
                    setVideoLists(videoList => [...videoList, { 
                        "vid": btoa(element.vid), 
                        "src": element.poster + "#t=0.1", 
                        "type": "video/mp4",
                        "like": element.like || 0,
                        "description": element.description || ""
                    }]);
                })
                setShowCategoriesVideoLoaded(true);
                return;
            }
        }).catch(error => {
            // Error handling
        });
    }

    function handleToggle(e) {
        if (e === 1) {
            setShowCategories(false);
            setShowCategoriesVideoSelected(false);
            setIsLoaded(false);
        }
        if (e === 2) {
            setShowCategories(true);
            setShowCategoriesVideoSelected(false);
        }
        if (e === 3) {
            setShowCategoriesVideoSelected(true);
            setShowCategoriesVideoLoaded(false);
        }
    }

    function navigateToVideo(video, index, liked) {
        navigate("/home/landing", {
            state: {
                savedVideo: {
                    vid: atob(video.vid),
                    src: video.src,
                    type: video.type,
                    like: liked,
                    description: video.description
                },
                index
            }
        });
    }

    return (
        <>
            <SfxMainAudio />
            <main className={styles.mainContainer}>
                <div className='d-flex flex-row'>
                    <h1 
                        className={(!showCategories ? `${styles.activeLink} ${styles.leftSide}` : `${styles.leftSide}`)} 
                        onClick={() => handleToggle(1)}
                    >
                        saved videos
                    </h1>
                    <h1 
                        className={(showCategories ? `${styles.activeLink} ${styles.rightSide}` : `${styles.rightSide}`)} 
                        onClick={() => handleToggle(2)}
                    >
                        categories
                    </h1>
                </div>
                {showCategories ? (
                    <>
                        {showCategoriesVideoSelected ? (
                            <section className={styles.vidoesContainer}>
                                {showCategoriesVideoLoaded ? (
                                    <>
                                        {videoList.map((item, index) => (
                                            <div
                                                key={`linkKey_${index}`}
                                                onClick={() => navigateToVideo(item, index, false)}
                                                className={styles.videoLink}
                                            >
                                                <img 
                                                    src={item.src} 
                                                    key={`posterKey_${index}`} 
                                                    alt="loading..." 
                                                    className={styles.videoImg} 
                                                />
                                            </div>
                                        ))}
                                        {!videoList.length && <p className="mt-2 mx-auto">NO VIDEOS FOUND</p>}
                                    </>
                                ) : <BufferingAnimation />}
                            </section>
                        ) : <Categories getCategory={getCategory} />}
                    </>
                ) : (
                    <section className={styles.vidoesContainer}>
                        {isLoaded ? (
                            <>
                                {videoList.map((item, index) => (
                                    <div
                                        key={`linkKey_${index}`}
                                        onClick={() => navigateToVideo(item, index, true)}
                                        className={styles.videoLink}
                                    >
                                        <img 
                                            src={item.src} 
                                            key={`posterKey_${index}`} 
                                            alt="loading..." 
                                            className={styles.videoImg} 
                                        />
                                    </div>
                                ))}
                                {!videoList.length && <p className="mt-2 mx-auto">NO VIDEOS SAVED</p>}
                            </>
                        ) : <BufferingAnimation />}
                    </section>
                )}
                <div className='mb-4'>
                    <button 
                        type="button" 
                        className={styles.backToVidesoButton} 
                        onClick={() => navigate("/home/landing")}
                    >
                        back to videos
                    </button>
                </div>
            </main>
        </>
    );
}

export default React.memo(SavedVideos);