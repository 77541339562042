import React, { useLayoutEffect, useEffect } from 'react';
import styles from '../../styles/login/login.module.css';
import pubg2 from '../../images/v1/login/pub2.png'
import fortnite2 from '../../images/v1/login/fort2.png'
import roblox2 from '../../images/v1/login/rob2.png'
import valorant2 from '../../images/v1/login/val2.png'
import callofduty2 from '../../images/v1/login/co2.png'

const ThankYouSubscribe = () => {

    const isIOSDevice = navigator.userAgent.match(/iPhone|iPad|iPod/i);
    useLayoutEffect(() => {
        document.body.style.backgroundColor = "#f8b600";
        document.querySelector("meta[name='theme-color']").setAttribute("content", "#f8b600");
        document.title = "Login | Ding";
        if (isIOSDevice) {
            document.body.style.backgroundColor = "#3d3c3a";
            document.querySelector("meta[name='theme-color']").setAttribute("content", "#3d3c3a");
            document.title = "Oops! Service Only For Android | Ding";
            document.body.innerHTML = `
                  <div style="display: flex; align-items: center; justify-content: center; height: 100vh; text-align: center;">
                    <div style="background-color: black; color: white; padding: 20px; border-radius: 8px; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);">
                      <h2 style="font-size: 24px; margin-bottom: 10px;font-family: 'system-ui', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif !important;">Oops! This Web App is for<br/>Android Only</h2>
                      <p style="font-size: 12px;line-height: 19px;font-family: 'system-ui', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif !important;">We apologize, but this web app is designed for Android devices.<br/>For the best experience, please use it on an Android device.</p>
                    </div>
                  </div>
                `;
            return;
        }
    }, []);

    useEffect(() => {
        loginInfo();
    }, []);




    const loginInfo = (event) => {
        setTimeout(
            () =>
                window.location.replace('/home'),
            4000
        );
    }




    return (
        <>
            <section className={styles.accessPopup}>
                <h1 className={styles.accessPopupWh1}>
                    Thank you for subscribing!<br />Your subscription was successfully completed.
                </h1>
            </section>
            <main className={styles.main + ' d-flex justify-content-center flex-column align-items-center filterBlur'}>
                <section className={styles.banner_section}>
                    <img src="" alt="" />
                    <figure className={styles.banner}>

                    </figure>
                    <h1 className={styles.title}>ding</h1>
                    {/* <h3 className={styles.subtitle_H}>learn, play &#x2130; win <br /> exciting rewards</h3> */}
                </section>
                <section className={styles.subtitle}>
                    <h3 className={styles.subtitle_H}>learn, play &#x2130; win <br /> exciting rewards</h3>
                </section>

                <section className={styles.games_banner + ' d-flex flex-row justify-content-center w-300 mt-15'}>
                    <div>
                        <img src={pubg2} alt="." />
                    </div>
                    <div>
                        <img src={fortnite2} alt="." />
                    </div>
                    <div>
                        <img src={roblox2} alt="." />
                    </div>
                    <div>
                        <img src={valorant2} alt="." />
                    </div>
                    <div>
                        <img src={callofduty2} alt="." />
                    </div>
                </section>

                <section className={styles.formSection + ` d-flex justify-content-center flex-column align-items-center mt-30`}>
                    <p className={styles.loginFormTitle + ' mb-5i'}>1 DAY FREE TRIAL</p>
                    <h1 className={styles.accessPopupWh1 + ' text-center mt-4'}>
                        Thank you for subscribing!<br />Your subscription was successfully completed.
                    </h1>
                    <div className='wmx-350'>
                        <p className={`${styles.consent} `} style={{ letterSpacing: "0.2px" }}>Service charges PKR 12+tax/week</p>
                        <p className={`${styles.consent} `}>You can unsubscrbe within the app</p>
                        <p className={`${styles.consent} `}>This service is auto renewed</p>
                    </div>
                </section>

            </main>
        </>
    );
}

export default React.memo(ThankYouSubscribe);