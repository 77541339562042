import { guestExpiry } from '../ApiCalls/Auth/api_v1_auth';
import { statisticsRecorder } from '../ApiCalls/TimeRecorder/api_v1_time_recorder';
import Cookies from 'universal-cookie';

var timer;
var timerStart;
var timeSpentOnSite = getTimeSpentOnSite();
var timeSpentOnDate = getTimeSpentOnDate();
const cookies = new Cookies();
const currentd = new Date();
const nextYear = new Date();
nextYear.setFullYear(currentd.getFullYear() + 1);
const cookieTimeCounter =
  setInterval(function () {
    cookeisCounter();
  }, 1000);
// console.log(window.location.pathname);
function cookeisCounter() {
  let isUuid = cookies.get('uid');
  let isUuidExp = cookies.get('uid_exp');
  // cookies.set('uid_exp', 0, { path: '/', expires: nextYear });
  if (isUuid && isUuidExp == undefined) {
    setTimeout(() => {
      cookies.set('uid_exp', 1, { path: '/', expires: nextYear });
      if (window.location.pathname != "/login") {
        guestExpiry();
        window.location.href = `${process.env.REACT_APP_LOGIN_URL}`
      }
    }, 90000);
    clearInterval(cookieTimeCounter);
  }

}

export function getTimeSpentOnSite() {
  timeSpentOnSite = parseInt(localStorage.getItem('ctx'));
  timeSpentOnSite = isNaN(timeSpentOnSite) ? 0 : timeSpentOnSite;
  return timeSpentOnSite;
}
export function getTimeSpentOnDate() {
  timeSpentOnDate = parseInt(localStorage.getItem('dx'));
  let formattedDate = dateFormatter(timeSpentOnDate);
  let current_date = dateFormatter(Date.now());
  if (isNaN(timeSpentOnDate)) {
    let dNow = Date.now();
    localStorage.setItem('dx', dNow);
    timeSpentOnDate = dNow;
  }
  else if (current_date > formattedDate && window.location.pathname != "/login") {
    saveStats();
    let dNow = Date.now();
    localStorage.setItem('dx', dNow);
    localStorage.setItem('ctx', 0);
    timeSpentOnDate = dNow;
    // console.log("cdate is greated",current_date);
  }
  else {
    timeSpentOnDate = localStorage.getItem('dx');
  }
  return timeSpentOnDate;
}
export function dateFormatter(tstamp) {
  //  var timestamp = new Date(parseInt(localStorage.getItem('dx')));
  let date = new Date(tstamp);
  let dd_mm_yyyy = date.toLocaleDateString();
  let yyyy_mm_dd = dd_mm_yyyy.replace(/(\d+)\/(\d+)\/(\d+)/g, "$3-$1-$2");
  return yyyy_mm_dd;
}

export function startCounting() {
  if (window.location.pathname === "/login") {
    return;
  }
  timerStart = Date.now();
  timer = setInterval(function () {
    timeSpentOnSite = getTimeSpentOnSite() + (Date.now() - timerStart);
    localStorage.setItem('ctx', timeSpentOnSite);
    timerStart = parseInt(Date.now());
    // Convert to seconds
    let ms = parseInt(timeSpentOnSite);
    let seconds = Math.floor(ms / 1000);
    let minutes = Math.floor(seconds / 60);
    if (minutes >= 2 && window.location.pathname != "/login") {
      // console.log(minutes);
      saveStats();
    }

  }, 1000);
}
startCounting();



function saveStats() {
  // Convert to seconds
  let ms = parseInt(localStorage.getItem('ctx'));
  let seconds = Math.floor(ms / 1000);
  let minutes = Math.floor(seconds / 60);
  // console.log(minutes);
  let ts = parseInt(localStorage.getItem('dx'));
  let cdate = dateFormatter(ts);
  let data = {
    "date": cdate,
    'mx': minutes,
  }
  statisticsRecorder(data).then(response => {
    const accessToken = response.headers['access_token'];
    if (accessToken != null) {
      localStorage.setItem('token', accessToken);
    }
    if (response.data.status === "ok" && response.status === 200) {
      localStorage.setItem('ctx', 0);
    }
  }).catch(error => {

  });
}

/* ---------- Stop the timer when the window/tab is inactive ---------- */

var stopCountingWhenWindowIsInactive = true;

if (stopCountingWhenWindowIsInactive) {

  if (typeof document.hidden !== "undefined") {
    var hidden = "hidden",
      visibilityChange = "visibilitychange",
      visibilityState = "visibilityState";
  } else if (typeof document.msHidden !== "undefined") {
    var hidden = "msHidden",
      visibilityChange = "msvisibilitychange",
      visibilityState = "msVisibilityState";
  }
  var documentIsHidden = document[hidden];

  document.addEventListener(visibilityChange, function () {
    if (documentIsHidden != document[hidden]) {
      if (document[hidden]) {
        // Window is inactive
        clearInterval(timer);
      } else {
        // Window is active
        startCounting();
      }
      documentIsHidden = document[hidden];
    }
  });
}