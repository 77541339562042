import React from "react";
import { useNavigate } from "react-router-dom";
import styles from '../../styles/redeem/redeemForm.module.css';
import SfxMainAudio from "../AutoPlayAudio/SfxMainAudio";
import { useEffect } from 'react';
function ThankYou(){
    let navigate = useNavigate(); 
    const navigateTo = ()=> navigate("/home")
    useEffect(() => {
        document.title = "Thank You | Ding";
    }, [])
    return(
        <>
        <SfxMainAudio/>
        <main style={{height:"80vh",display:"flex","justify-content":"center"}}>
            <section>
                    <div className="mt-4" id="redeem-form-submit">
                        <p className="color-black text-center outfitBold fs-24">Thank You!</p>
                        <div className="text-center">
                            <p className="color-black text-center outfitRegular fs-20 mt-15 line-height-12">Ding Team will contact<br />you within 24 hours to fulfill<br />your redemption and deliver<br />your reward.</p>
                            <p className="color-black text-center outfitRegular fs-20 line-height-12">Keep checking your inbox<br />frequently to avoid<br />missing your reward.</p>
                            <button className={styles.redeembtn} onClick={navigateTo}>Home</button>
                        </div>
                    </div>
            </section>
        </main>
        </>
    )
}

export default React.memo(ThankYou);