import styles from '../../styles/header/header.module.css';
import ding_token from '../../images/v1/home/ding_token.png';
import star_token from '../../images/v1/home/token.png';
import React, { useRef, useEffect, useState } from 'react';
import { coinsTimeout } from "../../ApiCalls/Header/api_v1_header";

function Header({ isPlaying, isBuffering }) {
    const [seconds, setSeconds] = useState(0);
    const [showAccumulation, setShowAccumulation] = useState(false);
    const coinsMaker = useRef(0);
    const counterRunning = useRef(false);
    // const [timeInterval,setTimeInterval] = useState();
    // let timeInterval = null;
    // if(timer == null || timer === 0)
    // {
    //     clearInterval(timeInterval);
    //     timeInterval = setInterval(incrementSeconds, 1000);
    // }

    // setTimeInterval(setInterval(incrementSeconds(0), 1000));
    // let timeInterval = null;
    // function incrementSeconds() {
    // timer += 1;
    // if(clearInterval=== 0){
    // if(timer > 59)
    // { setTimer(0);clearInterval(timeInterval);
    //  }
    // if(timer < 60)
    // { setTimer(timer+1); }
    // }
    // if(clearInterval=== 1){
    //     // When you want to cancel it:
    //     clearInterval(timeInterval);
    //     timeInterval = 0; // I just do this so I know I've cleared the interval
    // }
    // return true;
    // }

    const interval = useRef(null)

    useEffect(() => {
        if (seconds === 999) { stopCounter(); setSeconds(0) }
        if (coinsMaker.current === 10 || coinsMaker.current === 999) {
            coinsMaker.current = 0;
            coinsTimeout({ "t": 10 });

        }
    }, [seconds])

    const startCounter = () => interval.current = setInterval(() => {
        setSeconds(prevState => prevState + 1)
        coinsMaker.current++;
    }, 1000)

    const stopCounter = () => clearInterval(interval.current);

    // if (seconds == 0 || seconds == null) {
    //     let timeInterval = startCounter();
    // }


    const showCoinsAcumulation = () => {
        setShowAccumulation((prevState) => !prevState);
    };
    useEffect(() => {
        if (isPlaying && isBuffering === false && counterRunning.current === false) {
            counterRunning.current = true;
            startCounter();
        } else {
            counterRunning.current = false;
            stopCounter();
        }
    }, [isPlaying, isBuffering])


    return (
        <>
            <div className="d-flex flex-row position-relative justify-content-between z1 m-header" onContextMenu={(e) => e.preventDefault()}>
                <div className={`${styles.borderBottom} ${styles.ml} ${styles.colorWhite}`}>
                    <a href="#" className={styles.logoText}>ding</a>
                </div>
                <div className={`${styles.ml} ${styles.colorWhite} ${styles.timerWrapper} position-relative`} onClick={showCoinsAcumulation}>
                    {/* <a href="#" className={styles.headerLinks}>Get Rewards</a>{">"} */}
                    <img className={styles.starToken} src={star_token} alt="" width="30" height="30" />
                    <div className={styles.moveableTimer}>
                        <div id={styles.secondsCounter}> {seconds}</div>
                        <img className={styles.dingToken} src={ding_token} alt="" />
                    </div>
                </div>
            </div>
            {showAccumulation && (
                <div className={styles.accumulationInfo}>
                    <h2 style={{ fontSize: "20px", textAlign: "center" }}>
                        Every second <br />
                        you spend <br />
                        to watch video, <br />
                        you earn one GOLD COIN
                    </h2>
                    <div className={styles.closeAccumulation} onClick={showCoinsAcumulation}>
                        <p>X</p>
                    </div>
                </div>
            )
            }
        </>
    );
}

export default React.memo(Header);