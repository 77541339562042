import styles from '../../styles/header/redeemHeader.module.css';
import ding_token from '../../images/v1/home/ding_token.png';
import React, { useRef, useEffect, useLayoutEffect, useState } from 'react';
import { getCoins } from "../../ApiCalls/Redeem/api_v1_redeem";
// import {useLocation} from 'react-router-dom';
function Header() {
    let userCoinsRef = useRef();
    const storedAvatar = localStorage.getItem("avatar");
    const [avatar, setAvatar] = useState(storedAvatar !== null ? storedAvatar : ding_token);
    const [bg, setBg] = useState(storedAvatar !== null ? true : false);
    useLayoutEffect(() => {
        // const location = useLocation();
        // console.log(location.pathname.slice(1));
        // document.body.classList.add(className);
        document.body.style.backgroundColor = "#f8b600";
        // background: url('../../images/v1/login/wave.png');
        // background-repeat: repeat;
        // background-size: auto;
        // background-size: contain;
    }, []);
    useEffect(() => {
        getCoins().then(response => {
            userCoinsRef.current.innerHTML = response.data.data.coins;
            // console.log(response.data.data);

        }).catch(error => {
            // console.log(error);
        })

    }, [])

    return (
        <>
            <header>
                <div>
                    <img src={avatar} alt="" className={bg ? "userDpBg" : ""} />
                </div>
                <div>
                    <div>
                        <img src={ding_token} alt="" />
                    </div>
                    <div>
                        <p><span ref={userCoinsRef}>0</span><br />coins</p>
                    </div>
                </div>
            </header>
        </>
    )

}
export default React.memo(Header);